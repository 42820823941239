import React from "react"
import SearchLayout from "../components/layout/search-layout/search-layout"
import SearchPageContent from "../components/search/search-page-content/search-page-content"
import SEO from "../services/seo"

const SearchPage = ({ location }) => {
  console.log("current location (): ", location)

  return (
    <SearchLayout location={location}>
      <SEO title="Search" />
      <SearchPageContent location={location}></SearchPageContent>
    </SearchLayout>
  )
}

export default SearchPage
